<template>
  <div class="d-page-wrapper">
    <d-routed-tabs
      :items="tabs"
      :role-id="currentUser.roleId"
    ></d-routed-tabs>

     <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import pageMixin from '@/mixins/page.mixin'

import DRoutedTabs from '@/components/DRoutedTabs'


export default {
  components: {
    DRoutedTabs
  },


  mixins: [
    pageMixin
  ],


  data () {
    return {
      tabs: [
        { title: this.$t('settings.tabs.dealer'), route: 'dealerSettings' },
        { title: this.$t('settings.tabs.serviceTypes'), route: 'serviceTypes' },
        { title: this.$t('settings.tabs.templates'), route: 'templates' }
      ]
    }
  },


  computed: {
    ...mapGetters('user', ['currentUser'])
  },


  created () {
    this.setPageTitle(this.$t('nav.settings'))
  }
}
</script>
