const mixin = {
  // data () {
  //   return {
  //     isPageTitleSet: false
  //   }
  // },


  methods: {
    setPageTitle (title) {
      // this.isPageTitleSet = true
      this.$store.dispatch('pageSettings/setPageTitle', title)
    },

    startPageLoading () {
      this.$store.dispatch('pageSettings/setPageLoading', true)
    },

    stopPageLoading () {
      this.$store.dispatch('pageSettings/setPageLoading', false)
    }
  },


  beforeRouteLeave (to, from, next) {
    // if (this.isPageTitleSet) {
    //   this.$store.dispatch('pageSettings/setPageTitle', '')
    // }
    this.$store.dispatch('pageSettings/setPageLoading', false)
    next()
  }
}


export default mixin
