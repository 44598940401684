<template>
  <v-tabs>
    <v-tab
      v-for="tab of tabs"
      :key="tab.route"
      :to="{ name: tab.route }"
      replace
    >
      {{ tab.title }}
    </v-tab>
  </v-tabs>
</template>


<script>
import routerUtil from '@/utils/router.util'


export default {
  props: {
    items: Array,
    roleId: Number
  },


  computed: {
    tabs () {
      const tabs = this.items.filter(tab => routerUtil.isRouteAvailableForRole(tab.route, this.roleId))
      return tabs
    }
  },


  watch: {
    $route () {
      this.fixRoute()
    }
  },


  methods: {
    fixRoute () {
      const needRedirect = !this.tabs.find(x => x.route === this.$route.name)

      if (needRedirect && this.tabs.length) {
        this.$router.replace({ name: this.tabs[0].route })
      }
    }
  },


  created () {
    this.fixRoute()
  }
}
</script>
